// Generated by Framer (cac55f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/ILZXZ5AuNB8p8klAKXC6/fHXbNUB2sw9dvuqLNbAT/YXS5TuLOj.js";
import { OpenType } from "https://framerusercontent.com/modules/wtCWCDefZAF8uwRUa8z9/K7SB4rOAiUHTg03TJ5iQ/Overrides.js";
import * as sharedStyle from "../css/qEtUMzkDE";
const RichTextOpenType = OpenType(RichText);
const CaretFonts = getFonts(Caret);

const cycleOrder = ["x8VpEzfNu", "ROZ93pFsl"];

const serializationHash = "framer-nFC0V"

const variantClassNames = {ROZ93pFsl: "framer-v-3gfmbb", x8VpEzfNu: "framer-v-1rsexxd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "x8VpEzfNu", Open: "ROZ93pFsl"}

const getProps = ({height, hover, id, link, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Product", pP1Eq4CHO: link ?? props.pP1Eq4CHO, PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "x8VpEzfNu"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, pP1Eq4CHO, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "x8VpEzfNu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nFC0V", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={pP1Eq4CHO} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-1rsexxd", className)} framer-grwdd3`} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"x8VpEzfNu"} onMouseEnter={onMouseEnter1nzhzpa} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ROZ93pFsl: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichTextOpenType __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-9meii"} data-styles-preset={"qEtUMzkDE"}>Product</motion.p></React.Fragment>} className={"framer-6v6vwv"} layoutDependency={layoutDependency} layoutId={"lz8wfimgR"} style={{"--framer-paragraph-spacing": "0px"}} text={DApvjrdfG} variants={{ROZ93pFsl: {"--extracted-r6o4lv": "var(--token-80cd1325-a350-405b-932e-8e075517b6cc, rgb(219, 21, 25))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({ROZ93pFsl: {children: <React.Fragment><motion.p className={"framer-styles-preset-9meii"} data-styles-preset={"qEtUMzkDE"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-80cd1325-a350-405b-932e-8e075517b6cc, rgb(219, 21, 25)))"}}>Product</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1e55s66-container"} layoutDependency={layoutDependency} layoutId={"ovTHXbFGL-container"}><Caret height={"100%"} id={"ovTHXbFGL"} layoutId={"ovTHXbFGL"} variant={"xzGBfrweG"} width={"100%"} {...addPropertyOverrides({ROZ93pFsl: {variant: "dsZwaWdcl"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nFC0V [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nFC0V .framer-grwdd3 { display: block; }", ".framer-nFC0V .framer-1rsexxd { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-nFC0V .framer-6v6vwv { -webkit-user-select: none; flex: none; height: auto; pointer-events: none; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-nFC0V .framer-1e55s66-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nFC0V .framer-1rsexxd { gap: 0px; } .framer-nFC0V .framer-1rsexxd > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-nFC0V .framer-1rsexxd > :first-child { margin-left: 0px; } .framer-nFC0V .framer-1rsexxd > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 83
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ROZ93pFsl":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title","pP1Eq4CHO":"link"}
 * @framerImmutableVariables true
 */
const FramerO2eESzn9M: React.ComponentType<Props> = withCSS(Component, css, "framer-nFC0V") as typeof Component;
export default FramerO2eESzn9M;

FramerO2eESzn9M.displayName = "Trigger";

FramerO2eESzn9M.defaultProps = {height: 28, width: 83};

addPropertyControls(FramerO2eESzn9M, {variant: {options: ["x8VpEzfNu", "ROZ93pFsl"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Product", displayTextArea: false, title: "Title", type: ControlType.String}, pP1Eq4CHO: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerO2eESzn9M, [...CaretFonts, ...sharedStyle.fonts])